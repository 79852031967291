<template>
  <div class="page">
    <NavigationBar :title="title" v-show="is_navbar === 'true'"></NavigationBar>
    <div class="blacklistExposureContainer" :style="{ top: this.offsetTop }">
      <div class="header">
        <img
          src="@/static/images/securityImg1@2x.png"
          alt=""
          class="headImg"
        />
      </div>
      <div class="securityBody">
        <div class="securityBodyItem1">
          <img
            src="@/static/images/securityImg2@2x.png"
            alt=""
            class="securityBodyImg1"
            @click="goFindGuarantee"
          />
        </div>
        <div class="securityBodyItem2">
          <img
            src="@/static/images/securityImg3@2x.png"
            alt=""
            class="securityBodyImg2"
            @click="goCompanyProfile"
          />
          <img
            src="@/static/images/securityImg4@2x.png"
            alt=""
            class="securityBodyImg2"
            @click="goSecurity"
          />
        </div>
        <div class="securityBodyItem2">
          <img
            src="@/static/images/securityImg5@2x.png"
            alt=""
            class="securityBodyImg2"
            @click="goNewbie"
          />
          <img
            src="@/static/images/securityImg6@2x.png"
            alt=""
            class="securityBodyImg2"
            @click="goBlacklistExposure"
          />
        </div>
      </div>
      <div class="securityFoot">
        <img
          src="@/static/images/securityImg7@2x.png"
          alt=""
          class="footImg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { NavigationBar } from "@/components";
import { gitIosAppleModels } from "@/utils/gitIosAppleModels";
export default {
  name: "newsecurity",
  data() {
    return {
      title: "安全保障",
      offsetTop: "",
      isiOS: "",
      is_navbar: "true",
      is_share_navbar: "true",
    };
  },
  components: {
    NavigationBar,
  },
  mounted() {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid) {
      this.offsetTop = "1.226667rem";
      this.is_share_navbar = "true";
    } else if (isiOS) {
      let iosSafeArea = this.$route.query.iosSafeArea;
      let ios_6_7_8 = gitIosAppleModels();
      if (ios_6_7_8) {
        this.offsetTop = iosSafeArea
          ? iosSafeArea / (document.documentElement.clientWidth / 10) + "rem"
          : "1.226667rem";
      } else {
        this.offsetTop = iosSafeArea
          ? iosSafeArea / (document.documentElement.clientWidth / 10) + "rem"
          : 90 / (document.documentElement.clientWidth / 10) + "rem";
      }
      this.is_share_navbar = "false";
    }
    if (JSON.stringify(this.$route.query) != "{}") {
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
    if (this.is_navbar == "false") {
      this.offsetTop = "0rem";
    }
  },
  methods: {
    goCompanyProfile() {
      this.$router.push({
        name: "ImgContainer",
        query: {
          key: "company_profile_img",
          is_navbar: this.is_share_navbar,
        },
      });
    },
    goFindGuarantee() {
      this.$router.push({
        name: "ImgContainer",
        query: {
          key: "find_guarantee",
          is_navbar: this.is_share_navbar,
        },
      });
    },
    goSecurity() {
      this.$router.push({
        name: "Security",
        query: {
          from: "H5",
          is_navbar: this.is_share_navbar,
        },
      });
    },
    goNewbie() {
      this.$router.push({
        name: "Newbie",
        query: {
          from: "H5",
          is_navbar: this.is_share_navbar,
        },
      });
    },
    goBlacklistExposure() {
      this.$router.push({
        name: "BlacklistExposure",
        query: {
          from: "H5",
          is_navbar: this.is_share_navbar,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100vh;
  background: #0456fa;
  padding-bottom: env(safe-area-inset-bottom);
  .blacklistExposureContainer {
    width: 100%;
    height: calc(100vh - 46px);
    overflow-y: scroll;
    position: absolute;
    top: 46px;
    .header {
      .headImg {
        width: 100%;
        display: block;
      }
    }
    .securityBody {
      .securityBodyItem1 {
        .securityBodyImg1 {
          width: 100%;
          display: block;
        }
      }
      .securityBodyItem2 {
        display: flex;
        align-items: center;
        .securityBodyImg2 {
          width: 50%;
          display: block;
        }
      }
    }
    .securityFoot {
      .footImg {
        width: 100%;
        display: block;
      }
    }
  }
}
</style>
